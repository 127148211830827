import React from 'react';
import './App.css';
import pdf from './components/PrivacyPolicy.pdf'
// import EmergencyLighting from './components/images/screens/Home/EmergencyLighting.png';

function Footer(props) {

  return (
    <div class = {props.spacingClass}>
          <a href ="#" class = "colour">Engineer Login</a>
          <a href ={pdf} class = "colour">Privacy Policy</a>
    </ div>
  );
}

export default Footer;