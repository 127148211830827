import React from 'react';
import './App.css';
import {Nav} from 'react-bootstrap'

function NavElement(props) {

  function handleClick(e) {
    props.setImage(props.pageName)
  }

  return (
        <Nav.Item>
          <Nav.Link className= {props.class} onClick={handleClick}>
            {props.linkName}
          </Nav.Link>
        </Nav.Item>
  );
}

export default NavElement;