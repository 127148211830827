import React, { useState } from 'react';
import './App.css';
import NavBar from './NavBar.jsx';
import LargeSection from './LargeSection.jsx';
import Footer from './Footer.jsx';

function App() {
  const [image, setImage] = useState("HomeSectionImage");
  const [spacingClass, setSpacingClass] = useState("backgroundColour seperateFromTop");
  return (
    <div>
      <NavBar setImage = {setImage}/>
      <LargeSection image = {image} setImage = {setImage} setSpacingClass = {setSpacingClass}/>
      <Footer spacingClass = {spacingClass}/>
    </div>
  );
}

export default App;