import React from 'react';
import './App.css';
import mail from './Mail.PNG';

function NavElementMail() {
  return (
    <div>
      <img src={mail} class="mailIconSize iconElementTopSpacing mailElementPosition" alt="mail"/>
    </div>  
  );
}

export default NavElementMail;