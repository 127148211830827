import React from 'react';
import './App.css';
import phone from './Phone.PNG';

function NavElementPhone() {
  return (
    <div>
      <img src={phone} class="phoneIconSize iconElementTopSpacing phoneElementPosition" alt="phone"/> 
    </div>
  );
}

export default NavElementPhone;