import React from "react";
import {Nav, Navbar } from 'react-bootstrap';
import './App.css';
import NavElement from './NavElement.jsx';
import NavElementLogo from './NavElementLogo.jsx';
import NavElementPhone from './NavElementPhone.jsx';
import NavElementMail from './NavElementMail.jsx';

function NavBar(props) {
  let homePageName = "HomeSectionImage";

  let aboutLinkName = "About.";
  let aboutElementPosition = "active aboutElementPosition elementStyle";
  let aboutPageName = "AboutSectionImage";

  let offerLinkName = "What we Offer.";
  let offerElementPosition = "active offerElementPosition elementStyle";
  let offerPageName = "OfferSectionImage";

return (
  <Navbar fixed="top" expand="sm" bg="white">
    <Nav >
      <NavElementLogo setImage = {props.setImage} pageName={homePageName}/>
      <NavElement setImage = {props.setImage} linkName={aboutLinkName} class = {aboutElementPosition} pageName={aboutPageName}/>
      <NavElement setImage = {props.setImage} linkName={offerLinkName} class = {offerElementPosition} pageName={offerPageName}/>
      <NavElementPhone />
      <NavElementMail />
    </Nav>
  </ Navbar>
  );
}
 
export default NavBar;