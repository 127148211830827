import React from 'react';

import Detector from './components/images/screens/Home/Detector.png';
import AOV from './components/images/screens/Home/AOV.png';
import EmergencyLighting from './components/images/screens/Home/EmergencyLighting.png';

import CallPointAbout from './components/images/screens/About/CallPoint.png';
import BatteryAbout from './components/images/screens/About/Battery.png';
import EmergencyLightingAbout from './components/images/screens/About/EmergencyLighting.png';

import WhatWeOfferAOV from './components/images/screens/WhatWeOffer/WhatWeOfferAOV.png';
import WhatWeOfferCentralBattery from './components/images/screens/WhatWeOffer/WhatWeOfferCentralBattery.png';
import WhatWeOfferEmergencyLighting from './components/images/screens/WhatWeOffer/WhatWeOfferEmergencyLighting.png';
import WhatWeOfferInstallation from './components/images/screens/WhatWeOffer/WhatWeOfferInstallation.png';
import WhatWeOfferServicing from './components/images/screens/WhatWeOffer/WhatWeOfferServicing.png';

import InstallationWidget from './components/images/widgets/Installation.png';
import SmokeVentWidget from './components/images/widgets/AOV.png';
import ServicingWidget from './components/images/widgets/Servicing.png';
import BatteryWidget from './components/images/widgets/Battery.png';
import EmergencyLightingWidget from './components/images/widgets/EmergencyLighting.png';

function LargeImageSection(props) 
{
    function installationHandleClick(e)
    {
        props.setImage("WhatWeOfferInstallation")
    }

    function smokeVentHandleClick(e) 
    {
        props.setImage("WhatWeOfferAOV")
    }

    function servicingHandleClick(e) 
    {
        props.setImage("WhatWeOfferServicing")
    }

    function BatteryHandleClick(e) 
    {
        props.setImage("WhatWeOfferCentralBattery")
    }

    function EmergencyLightingHandleClick(e) 
    {
        props.setImage("WhatWeOfferEmergencyLighting")
    }

    return(
    <div>
        {props.image == 'HomeSectionImage' &&
        <div>
            {props.setSpacingClass("backgroundColour seperateFromTop")}
            <div class="fadein">
                <img class="fitToScreen" id="thirdFade" src={EmergencyLighting}/>
                <img class="fitToScreen" id="secondFade" src={AOV}/>
                <img class="fitToScreen" id="firstFade" src={Detector}/>
            </div> 
        </div> 
        }

        {props.image == 'AboutSectionImage' &&
        <div>
            {props.setSpacingClass("backgroundColour seperateFromTop")}
            <div class="fadein">
                <img class="fitToScreen" id="thirdFade" src={EmergencyLightingAbout}/>
                <img class="fitToScreen" id="secondFade" src={BatteryAbout}/>
                <img class="fitToScreen" id="firstFade" src={CallPointAbout}/>
            </div>   
        </div>               
        }   
        {props.image == 'OfferSectionImage' &&
            <div>
                    {props.setSpacingClass("backgroundColour seperateFromTopNoTransition")}
                    <img onClick={servicingHandleClick} class="widgetFitToScreen" src={ServicingWidget}/>
                    <img onClick={installationHandleClick} class="widgetFitToScreen" src={InstallationWidget}/>
                    <img onClick={smokeVentHandleClick} class="widgetFitToScreen" src={SmokeVentWidget}/>
                    <img onClick={EmergencyLightingHandleClick} class="widgetFitToScreen" src={EmergencyLightingWidget}/>
                    <img onClick={BatteryHandleClick} class="widgetFitToScreen" src={BatteryWidget}/>
            </div>
        }
        {props.image == 'WhatWeOfferInstallation' &&
            <img class="fitToScreen" src={WhatWeOfferInstallation}/>
        }   
        {props.image == 'WhatWeOfferAOV' &&
            <img class="fitToScreen" src={WhatWeOfferAOV}/>
        }   
        {props.image == 'WhatWeOfferCentralBattery' &&
            <img class="fitToScreen" src={WhatWeOfferCentralBattery}/>
        }   
        {props.image == 'WhatWeOfferEmergencyLighting' &&
            <img class="fitToScreen" src={WhatWeOfferEmergencyLighting}/>
        }
        {props.image == 'WhatWeOfferServicing' &&
            <img class="fitToScreen" src={WhatWeOfferServicing}/>
        }     
    </div>
    );
}

export default LargeImageSection;