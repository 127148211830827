import React from 'react';
import './App.css';
import fuegoLogo from './FuegoLogo.png';

function NavElementLogo(props) {
  function handleClick(e) {
    props.setImage(props.pageName)
  }
  return (
      <img onClick={handleClick} class = "logoFit" src={fuegoLogo} alt="logo"/>
  );
}

export default NavElementLogo;